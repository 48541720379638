<!-- eslint-disable vue/no-v-html -->
<template>
  <fragment v-if="!isLoading">
    <div class="d-none d-md-block mt-3 mb-1">
      <v-row>
        <v-card
          class="my-2"
          width="100%"
        >
          <v-container
            grid-list-xs
            class="pa-6"
          >
            <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
              <v-col cols="6">
                <div class="d-block">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      md="12"
                      class="mb-0 pb-0"
                    >
                      <b> {{ cotizadorHotelsResultSelect.hotel.name }} </b>

                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          :md="cotizadorHotelsResultSelect.hotel.categoria.plus ? '4' : '12'"
                        >
                          <v-rating
                            background-color="default"
                            color="primary"
                            :empty-icon="icons.mdiStarOutline"
                            :full-icon="icons.mdiStar"
                            :half-icon="icons.mdiStarHalfFull"
                            length="5"
                            readonly
                            size="20"
                            dense
                            half-increments
                            :value="cotizadorHotelsResultSelect.hotel.categoria.cant_estrellas"
                          ></v-rating>
                        </v-col>
                        <v-col
                          v-if="cotizadorHotelsResultSelect.hotel.categoria.plus"
                          cols="12"
                          md="2"
                        >
                          PLUS
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <p class="mb-3 mt-5">
                        <span>{{
                          cotizadorHotelsResultSelect.hotel.operador
                            ? `${$t('lbl.cadena')}: ${cotizadorHotelsResultSelect.hotel.operador.name}`
                            : ''
                        }}</span><br />
                        <span>{{
                          cotizadorHotelsResultSelect.proveedor
                            ? `${$t('lbl.proveedor')}: ${cotizadorHotelsResultSelect.proveedor.name}`
                            : ''
                        }}</span>
                        <span v-if="cotizadorHotelsResultSelect.sources.length > 0">
                          ({{ cotizadorHotelsResultSelect.sources[0] | capitalize }})
                        </span>
                        <br />
                        <span>{{
                          `${$t('lbl.destino')}: ${cotizadorHotelsResultSelect.hotel.city}, ${
                            cotizadorHotelsResultSelect.hotel.country
                          }`
                        }}</span><br />
                        <span>{{
                          cotizadorHotelsResultSelect.hotel.tipo_destino
                            ? `${$t('lbl.tipo')}: ${cotizadorHotelsResultSelect.hotel.tipo_destino.name}`
                            : ''
                        }}</span><br />
                      </p>
                    </v-col>

                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-row no-gutters>
                        <v-col
                          v-if="cotizadorHotelsResultSelect.hotel.cant_departament.cant_rooms"
                          cols="12"
                          md="2 "
                        >
                          <v-tooltip
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-bed-outline
                              </v-icon>
                              <span>
                                {{ cotizadorHotelsResultSelect.hotel.cant_departament.cant_rooms }}
                              </span>
                            </template>
                            <span>{{ $t('lbl.cantRoom') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col
                          v-if="cotizadorHotelsResultSelect.hotel.cant_departament.cant_restaurant"
                          cols="12"
                          md="2"
                        >
                          <v-tooltip
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-silverware-fork-knife
                              </v-icon>
                              <span>
                                {{ cotizadorHotelsResultSelect.hotel.cant_departament.cant_restaurant }}
                              </span>
                            </template>
                            <span>{{ $t('lbl.cantRest') }}</span>
                          </v-tooltip>
                        </v-col>

                        <v-col
                          v-if="cotizadorHotelsResultSelect.hotel.cant_departament.cant_bars"
                          cols="12"
                          md="2"
                        >
                          <v-tooltip
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-glass-cocktail
                              </v-icon>
                              <span>
                                {{ cotizadorHotelsResultSelect.hotel.cant_departament.cant_bars }}
                              </span>
                            </template>
                            <span>{{ $t('lbl.cantBars') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col
                          v-if="cotizadorHotelsResultSelect.hotel.cant_departament.cant_pool"
                          cols="12"
                          md="2"
                        >
                          <v-tooltip
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-pool
                              </v-icon>
                              <span>
                                {{ cotizadorHotelsResultSelect.hotel.cant_departament.cant_pool }}
                              </span>
                            </template>
                            <span>{{ $t('lbl.cantPiscinas') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col
                          v-if="cotizadorHotelsResultSelect.hotel.cant_departament.cant_coffee"
                          cols="12"
                          md="2"
                        >
                          <v-tooltip
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-coffee
                              </v-icon>
                              <span>
                                {{ cotizadorHotelsResultSelect.hotel.cant_departament.cant_coffee }}
                              </span>
                            </template>
                            <span>{{ $t('lbl.cantCafeterias') }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <!-- SERVICIOS
                      <v-tooltip
                        v-for="(serv, indServ) in cotizadorHotelsResultSelect.hotel.servicios_id"
                        :key="indServ"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            class="mr-1"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{
                              services.filter(e => e.id === serv).length > 0
                                ? services.filter(e => e.id === serv)[0].icon
                                : ''
                            }}
                          </v-icon>
                        </template>
                        <span>{{
                          services.filter(e => e.id === serv).length > 0
                            ? services.filter(e => e.id === serv)[0].name
                            : ''
                        }}</span>
                      </v-tooltip> -->
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="6">
                <v-img
                  :src="resource === 's3' ? rutaS3 + image : rutaPublic + image"
                  contain
                  size="100"
                  height="auto"
                >
                  <!--PARA MOSTRAR REBAJAS U OFERTAS
                    <template v-slot:default>
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                        offset-md="2"
                      >
                        <v-chip
                          class="mx-2 mt-5"
                          color="primary"
                        >
                          VIP
                        </v-chip>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-btn
                          class="mx-2 mt-5"
                          fab
                          small
                        >
                          <v-icon
                            color="primary"
                            dark
                          >
                            mdi-heart-outline
                          </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      ></v-col>
                    </v-row>
                  </template>-->
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card
          class="my-2"
          width="100%"
        >
          <v-container
            grid-list-xs
            class="pa-6"
          >
            <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
              <v-col cols="12">
                <div class="d-block">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <p class="mb-0 d-flex justify-start">
                        <b>{{ $t('lbl.detallesReserva') }}</b>
                      </p>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                      class="pt-0 mt-0"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <b>{{ $t('lbl.dateIn') }}</b>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-icon
                            color="primary"
                            class="mr-2"
                            small
                          >
                            mdi-calendar-range
                          </v-icon>
                          <span class="caption">
                            {{ $moment(cotizadorHotels.dateIn).format('ll') }}<br />
                            <span class="ml-7">{{
                              cotizadorHotelsResultSelect.hotel.checkIn
                                ? `${$t('lbl.fromAl')} ${cotizadorHotelsResultSelect.hotel.checkIn}`
                                : `${$t('lbl.fromAl')} 4:00 pm`
                            }}</span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="pt-0 mt-0"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <b>{{ $t('lbl.dateOut') }}</b>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-icon
                            color="primary"
                            class="mr-2"
                            small
                          >
                            mdi-calendar-range
                          </v-icon>
                          <span class="caption">
                            {{ $moment(cotizadorHotels.dateOut).format('ll') }}<br />
                            <span class="ml-7">{{
                              cotizadorHotelsResultSelect.hotel.checkOut
                                ? `${$t('lbl.toAl')} ${cotizadorHotelsResultSelect.hotel.checkOut}`
                                : `${$t('lbl.toAl')} 12:00 pm`
                            }}</span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="pt-0 mt-0"
                    >
                      <b>{{ $t('lbl.nights') | capitalize }}</b>: {{ cotizadorHotelsResultSelect.days_search }}<br />
                      <b>{{ $t('menu.habitaciones') }}</b>: {{ cotizadorHotelsResultSelect.ocupation.length }}<br />
                      <fragment v-if="cantAdults > 0">
                        <b>{{ $t('lbl.adl') }}.</b>: {{ cantAdults }}
                      </fragment>
                      <fragment v-if="cantMenor > 0">
                        <b>{{ $t('lbl.mnr') }}.</b>: {{ cantMenor }}
                      </fragment>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card
          v-if="cotizadorHotelsResultSelect.hotel.descripcion"
          class="my-2"
          width="100%"
        >
          <v-container
            grid-list-xs
            class="pa-6"
          >
            <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
              <v-col cols="12">
                <div class="d-block">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <b>{{ $t('lbl.description') }}</b>:<br />
                          <br />
                          <span
                            class="text-justify"
                            v-bind.prop="htmlToText"
                          ></span>
                          <a
                            v-if="!showMoreDesc"
                            :style="`color: ${$vuetify.theme.themes.light.primary}`"
                            @click="showMoreDesc = !showMoreDesc"
                          >{{ $t('lbl.moreLeer') }}</a>
                          <a
                            v-else
                            :style="`color: ${$vuetify.theme.themes.light.primary}`"
                            @click="showMoreDesc = !showMoreDesc"
                          >{{ $t('lbl.lessLeer') }}</a>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!--SUPLEMENTOS
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <b>{{ $t('menu.suplementos') }}</b>
                        </v-col>
                        <v-col
                          v-for="(suplemento, indSuple) in cotizadorHotelsResultSelect.suplementos"
                          :key="indSuple"
                          cols="12"
                          md="6"
                        >
                          <v-checkbox
                            v-model="suplemento.check"
                            :label="suplemento.suplemento.name"
                            :disabled="suplemento.obligatorio"
                            hide-details
                            class="my-0"
                            @change="verifySuplement(suplemento)"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>-->
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card
          class="my-2"
          width="100%"
        >
          <v-container grid-list-xs>
            <v-row
              v-for="(ocupation, indO) in cotizadorHotels.ocupation"
              :key="indO"
              style="border: 1px solid #e5e5e5; border-radius: 5px;"
            >
              <HotelRoom
                :regimenes="regimenNomAll"
                :ocupation="ocupation"
                :index="indO"
                @applyMarkups="applyMarkups"
              />
            </v-row>
          </v-container>
        </v-card>

        <!--<v-card
          class="my-2"
          width="100%"
        >
          <v-container
            grid-list-xs
            class="pa-6"
          >
            <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
              <div class="d-block">
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    class="pt-5 pb-0"
                  >
                    <b class="ml-5">
                      {{ $t('lbl.infoImportant') }}
                    </b>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="pt-0 pb-0 mt-0 mb-0"
                  >
                    <span class="ml-5">
                      <v-checkbox
                        v-model="infoImportant"
                        :label="$t('lbl.readTermsConditions')"
                        hide-details
                        class="my-0 ml-3 pt-0 pb-0"
                        @click="changeInfoImportant"
                      ></v-checkbox>
                    </span>
                  </v-col>
                </v-row>

                !--CONDITIONES GENERALES--
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-expansion-panels>
                      <v-expansion-panel
                        v-for="(cate, ind) in categCondicionesGenerales"
                        :key="ind"
                      >
                        <v-expansion-panel-header>
                          {{ cate.name }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <fragment
                            v-for="(cond, indCond) in condicionesGenerales"
                            :key="indCond"
                          >
                            <fragment v-if="cate.id === cond.category_id">
                              <h5>
                                <span v-if="condicionesGenerales_id.includes(cond.id)">
                                  <v-checkbox
                                    v-model="condicionesGenerales_id"
                                    disabled
                                    :label="cond.description"
                                    :value="cond.id"
                                    hide-details
                                    class="pt-0"
                                  ></v-checkbox>
                                </span>
                              </h5>
                            </fragment>
                          </fragment>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    !--<v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ $t('lbl.termsConditions') }}
                          <template v-slot:actions>
                            <v-icon color="primary">
                              $expand
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ $t('lbl.policesCancele') }}
                          <template v-slot:actions>
                            <v-icon color="primary">
                              $expand
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ $t('lbl.docConductor') }}
                          <template v-slot:actions>
                            <v-icon color="primary">
                              $expand
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ $t('lbl.policesComb') }}
                          <template v-slot:actions>
                            <v-icon color="primary">
                              $expand
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>--
                  </v-col>
                </v-row>
              </div>
            </v-row>
          </v-container>
        </v-card>-->
      </v-row>
    </div>
    <!--FORM FINALIZAR
    <FormFinalizarCar />-->
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiStarOutline,
  mdiStar,
  mdiStarHalfFull,
} from '@mdi/js'

import HotelRoom from './HotelRoom.vue'

export default {
  components: {
    HotelRoom,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      rutaS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      itemsOrigen: [],
      isLoading: true,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      image: null,
      resource: 'local',
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      recogida: null,
      entrega: null,
      vrad: 0,
      value: null,
      value1: null,
      icons: {
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
      },
      infoImportant: false,
      is_nivel_propietario: false,
      categCondicionesGenerales: [],
      condicionesGenerales: [],
      condicionesGenerales_id: [],
      checkbox1: true,
      services: [],
      regimenNomAll: [],
      showMoreDesc: false,
      incluyeComition: 0,
      precioWithMUP: 0,
      precioWithDESC: 0,
      currencyPVP: localStorage.getItem('currencyPVP'),
      currencyCodePVP: localStorage.getItem('currencyCodePVP'),
    }
  },
  computed: {
    ...mapState({
      cotizadorHotels: state => state.app.cotizadorHotels,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultSelect: state => state.app.cotizadorHotelsResultSelect,
      cotizadorPaxCarSelect: state => state.app.cotizadorPaxCarSelect,

      // MARKUPS
      cotizadorHotelsRomms: state => state.app.cotizadorHotelsRomms,
    }),
    cantAdults() {
      let cantA = 0
      this.cotizadorHotelsResultSelect.rooms.forEach(element => {
        cantA += element.cantAdulEdad
      })

      return cantA
    },
    cantMenor() {
      let cantM = 0
      this.cotizadorHotelsResultSelect.rooms.forEach(element => {
        cantM += element.cantTeenEdad
        cantM += element.cantMnrEdad
        cantM += element.cantInfEdad
      })

      return cantM
    },
    htmlToText() {
      const text = {
        innerHTML: !this.showMoreDesc
          ? `${this.cotizadorHotelsResultSelect.hotel.descripcion.slice(0, 100)}...`
          : this.cotizadorHotelsResultSelect.hotel.descripcion,
      }

      return text
    },

    // MARKUPS
    precioInitial() {
      let priceTotal = 0

      this.cotizadorHotelsRomms.forEach(ocupa => {
        priceTotal += ocupa.price
      })

      return priceTotal
    },
    precioWithDescuento() {
      let priceTotal = this.precioInitial

      if (sessionStorage.getItem('markup')) {
        let incluyeComition = 0

        // %
        if (sessionStorage.getItem('valueMarkup') === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          incluyeComition = (parseFloat(priceTotal) * parseFloat(sessionStorage.getItem('markup'))) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + incluyeComition
        } // USD
        if (sessionStorage.getItem('valueMarkup') === this.currencyCodePVP) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          incluyeComition = parseFloat(sessionStorage.getItem('markup'))
          priceTotal = parseFloat(priceTotal) + incluyeComition
        }
      }

      if (sessionStorage.getItem('descuento') !== 'null') {
        let incluyeDescuento = 0

        // %
        if (sessionStorage.getItem('valueDescuento') === '%') {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          incluyeDescuento = (this.cotizadorHotelsResultSelect.minPrice * parseFloat(sessionStorage.getItem('descuento'))) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) - incluyeDescuento
        } // USD
        if (sessionStorage.getItem('valueDescuento') === this.currencyCodePVP) {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          incluyeDescuento = parseFloat(sessionStorage.getItem('descuento'))
          priceTotal = parseFloat(priceTotal) - incluyeDescuento
        }
      }

      return priceTotal
    },
    precioTotal() {
      let priceTotal = 0

      this.cotizadorHotelsRomms.forEach(ocupa => {
        priceTotal += ocupa.priceWithDescuento
      })

      return priceTotal
    },
  },
  watch: {
    searchRooms(val) {
      if (val !== null && val.length > 1) {
        this.filterRooms(val.toLowerCase())
      }
    },
  },
  created() {
    this.profile()
    this.getHotel()
    this.getServices()
    this.getRegimen()

    // this.getCategCondiciones()
    // this.getCondiciones()

    // this.condicionesGenerales_id = this.cotizadorHotelsResultSelect.conditions
  },
  methods: {
    ...mapMutations([
      'deleteDestinoCotizadorFligth',
      'setSearchingFlightCotizador',
      'setDeclineTerms',
      'setCotizadorHotelsSelectMarkups',
    ]),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1 || response.data.data.nivel === 0) {
            this.is_nivel_propietario = true
            this.infoImportant = true

            // this.setDeclineTerms(false)
          }
        })
    },
    filterRooms(v) {
      this.itemsRooms = []
      if (v === '') {
        this.itemsRooms = []
      } else {
        this.itemsRooms = this.roomsNomAll.filter(e => e.name.toLowerCase())
      }
    },
    getOficinas() {
      if (this.cotizadorCars.recogida) {
        if (this.cotizadorCars.recogida.type === 'oficina') {
          this.axios
            .get(`nom_oficina_renta_cars/${this.cotizadorCars.recogida.id}`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              this.recogida = res.data.data.data
              if (!this.cotizadorCars.entrega) {
                this.entrega = this.recogida
              }
            })
        } else if (this.cotizadorCars.recogida.type === 'destino') {
          this.axios
            .get(`nom_oficina_renta_cars/${this.cotizadorHotelsResultSelect.recogida.id}`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              this.recogida = res.data.data.data
              if (!this.cotizadorCars.entrega) {
                this.entrega = this.recogida
              }
            })
        }
      }
      if (this.cotizadorCars.entrega) {
        if (this.cotizadorCars.entrega.type === 'oficina') {
          this.axios
            .get(`nom_oficina_renta_cars/${this.cotizadorCars.entrega.id}`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              this.entrega = res.data.data.data
            })
        } else if (this.cotizadorCars.entrega.type === 'destino') {
          this.axios
            .get(`nom_oficina_renta_cars/${this.cotizadorHotelsResultSelect.entrega.id}`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              this.entrega = res.data.data.data
            })
        }
      }
    },
    getServices() {
      this.axios
        .get('nom_servicios_hotels?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.services = res.data.data
          }
        })
    },
    getHotel() {
      if (this.cotizadorHotelsResultSelect.hotel.galery === null) {
        this.image = 'config/hotel_placeholder.jpg'
      } else if (this.cotizadorHotelsResultSelect.hotel.galery_random === null) {
        this.image = 'config/hotel_placeholder.jpg'
      } else {
        this.image = this.cotizadorHotelsResultSelect.hotel.galery_random
        this.resource = this.cotizadorHotelsResultSelect.hotel.resource_random
      }

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    getCategCondiciones() {
      this.axios
        .get('nom_categories_conditions?for_car=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categCondicionesGenerales = res.data.data
          }
        })
    },
    getCondiciones() {
      this.axios
        .get('nom_conditions_generals?for_car=1&per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.condicionesGenerales = res.data.data
          }
        })
    },
    verifySuplement(suplemento) {
      const slugSup = suplemento.suplemento.slug.toLowerCase()
      if (suplemento.check) {
        // this.cotizadorHotelsResultSelect.tarifaAllDay += suplemento.priceAll
        // this.cotizadorHotelsResultSelect.priceTotal += suplemento.priceAll
        // this.cotizadorHotelsResultSelect.priceWithMarkup += suplemento.priceAll
        // this.cotizadorHotelsResultSelect.priceWithDescuento += suplemento.priceAll

        if (slugSup === 'conductor-extra') {
          this.cotizadorPaxCarSelect.conductorAditional = true
        }
      } else {
        // this.cotizadorHotelsResultSelect.tarifaAllDay -= suplemento.priceAll
        // this.cotizadorHotelsResultSelect.priceTotal -= suplemento.priceAll
        // this.cotizadorHotelsResultSelect.priceWithMarkup -= suplemento.priceAll
        // this.cotizadorHotelsResultSelect.priceWithDescuento -= suplemento.priceAll

        // eslint-disable-next-line no-lonely-if
        if (slugSup === 'conductor-extra') {
          this.cotizadorPaxCarSelect.conductorAditional = false
        }
      }
    },
    changeInfoImportant() {
      this.setDeclineTerms(!this.infoImportant)
    },
    getRegimen() {
      this.axios
        .get('nom_regimen_alimenticio?page=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.regimenNomAll = res.data.data
          }
        })
    },

    // MARKUPS
    applyMarkups() {
      if (this.precioInitial < this.precioWithDescuento) {
        let cont = 0
        this.cotizadorHotelsRomms.forEach(element => {
          cont += element.cant
        })
        const descByRoom = ((this.precioWithDescuento - this.precioInitial) / cont).toFixed(2)

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.cotizadorHotelsRomms.length; i++) {
          this.cotizadorHotelsRomms[i].priceWithDescuento = this.cotizadorHotelsRomms[i].cant * descByRoom + this.cotizadorHotelsRomms[i].price
        }

        this.setPrecioMarkup()
        this.setPrecioDescuento()
      } else {
        this.$toast.error(this.$t('msg.markupsNoValid'))
      }
    },
    setPrecioMarkup() {
      let priceTotal = this.precioInitial

      const markup = sessionStorage.getItem('markup')
      if (markup) {
        // %
        if (sessionStorage.getItem('valueMarkup') === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = (parseFloat(priceTotal) * parseFloat(markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + this.precioWithMUP
        } // USD
        if (sessionStorage.getItem('valueMarkup') === this.currencyCodePVP) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = parseFloat(this.markup)
          priceTotal = parseFloat(priceTotal) + parseFloat(markup)
        }
      }

      this.priceMarkup = priceTotal - this.precioInitial
    },
    setPrecioDescuento() {
      let priceTotal = this.precioInitial

      const markup = sessionStorage.getItem('markup')
      if (markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = (parseFloat(priceTotal) * parseFloat(markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + this.precioWithMUP
        } // USD
        if (sessionStorage.getItem('valueMarkup') === this.currencyCodePVP) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = parseFloat(markup)
          priceTotal = parseFloat(priceTotal) + parseFloat(markup)
        }
      }
      if (sessionStorage.getItem('descuento')) {
        // %
        if (sessionStorage.getItem('valueDescuento') === '%') {
          this.priceDescuento = priceTotal - this.precioInitial - ((this.cotizadorHotelsResultSelect.minPrice * parseFloat(sessionStorage.getItem('descuento'))) / 100)
        } // USD
        if (sessionStorage.getItem('valueDescuento') === this.currencyCodePVP) {
          this.priceDescuento = parseFloat(sessionStorage.getItem('descuento'))
        }
      }
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
